/* You can add global styles to this file, and also import other style files */
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "materialize";
@import "theme";
@import "mixin";
@import '~ngx-toastr/toastr';
@import 'prismjs/themes/prism.css';


@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

//-----LAYOUT-----//
html,
body {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    font-family: "Poppins", Arial, sans-serif;
    color: #2F2F2F;
    font-weight: 300;
    background-color: #ffffff;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
}

header {
    .mat-toolbar-multiple-rows,
    .mat-toolbar-row, .mat-toolbar-single-row {
        height: 50px;
        min-height: 50px;
    }
    mat-toolbar-row .mat-flat-button {
        max-height: 32px;
    }
    mat-toolbar-row .rounded-icon-btn.dark {
        background-color: #fff !important;
    }

    @include mobile-sm_max {
        width: 100%;
    }
}

main {
    min-height: 94vh;
}

footer {
    margin-top: -73px;
    height: 73px;
    clear: both;
    overflow: hidden;

    .container {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        color: #333;
        font-size: 12px;
        font-weight: 400;

        a {
            color: #15ce37;
            font-weight: 400;
        }
    }
}

a {
    color: #15ce37;
    text-decoration: underline;

    &:hover{
        text-decoration: none;
        cursor: pointer;
    }
}

.visible {
    visibility: visible;
    display: block;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

main {
    .container {
        margin: 0 auto;
    }
}

.container {
    padding: 30px 15px;

    @include mobile-sm_max {
        width: 100%;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.center {
    text-align: center;
}

.d-flex {
    display: flex;
    &-center {
        display: flex;
        align-items: center;
    }
    &-center-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.mat-toolbar.mat-accent {
    background-color: transparent;
    border-color: transparent;
}


app-navbar.dark {
    .mat-toolbar.mat-accent {
        color: #fff;

        .logout > span {
            color: #fff;
        }
    }

    #welcome-user {
        background-image: url(/assets/logo_quiq-text_sm-dark-2.png) !important;
    }
}

mat-sidenav-container {
    height: 100vh;

    //--LIGHT MODE COLOR THEME--//
    &.light {

        #logo_dark {
            display: none;
        }

        #logo_light {
            display: block;
        }

        #points-wrapper {
            #points-container {
                background-color: #F6F6F6;
            }
        }

        #points-info_modal .arrow-up {
            border-bottom: 10px solid #f6f6f6;
        }

        mat-sidenav-content {
            background-color: #e4e4e4;
        }

        button.cancel {
            background-color: #adadad !important;
        }

        .backTop {
            color: #666;
        }

        .backTop_section {
            color: #999898;
        }

        #app-menu {
            #menu_logo {
                #menu_logo-link {
                    background-image: url(/assets/logo_quiq-text_sm.png) !important;
                }
            }
        }

        #about-us_wrapper {
            .circle-bg {
                background: #d8d8d8; 
                border: 5px solid #d2d1d1;
            }

            .about-section {
                h3 {
                    color: #565656;
                }

                p, li {
                    color: #616161;
                }
            }
        }
            
        #uploader-wrapper {
            .upload {
                background-color: #ececec;

                #upload-ready_wrapper {
                    .upload-cost_header {
                        color:#333;
                    }
                }

                .faux-button {
                    border: 1px solid #adadad;
                    background-color: #b5b1b1;
                    color: #fff;

                    &:hover {
                        background-color: #a2a2a2;
                    }
                }
            }

            #choose-format_wrapper {
                .mat-checkbox {
                    .mat-checkbox-layout {
                        background-color: #fff;
                    }
                }
            }
        }

        mat-sidenav {
            background-color: #f0f0f0 !important;
            
            #points-wrapper {
                #points-icon {
                    background: url("../assets/icon_points.png") no-repeat;
                }
            }

            .mat-drawer-inner-container {
                background-color: #f0f0f0;
                height: auto;

                #app-menu {
                    li {
                        color: #9a9a9c;
                        padding: 15px 0;
                        background-color: #f0f0f0;
                        margin: 1px 0;

                        &.route-active {
                            color: #666666;
                            background-color: #fff;
                            border-right: 4px solid #b3b3b3;

                            h4 {
                                font-weight: 500;
                            }

                            &:hover {
                                color: #666;
                            }
                        }

                        &:hover {
                            color: #6c6c6c;
                        }
                    }
                }
            }
        }

        #upload-progress_wrapper {
            .upload-progress-bar {
                #progress-bar {
                    .stop-upload-btn {
                        background-color: #f6f6f6;
                    }

                    .complete-icon {
                        background-color: #fff;
                    }
                }
            }
        }

        #upload-histories-wrapper {
            #multiple-downloads_row {
                button {
                    border: 1px solid #d8d6d6;
                    background-color: #f1f1f1;
                    color: #696969;

                    span {
                        color: #757575 !important;
                    }

                    &:hover {
                        background-color: #fff !important;
                    }
                }
            }
        }

        #upload-item {
            .body {
                .row {
                    background-color: #fbfbfb;
                    border: 1px solid #e6e6e6;

                    &:hover {
                        background-color: #f7f7f7;
                        cursor: pointer;
                    }

                    .row-container {
                        .loading-details {
                            background: rgb(228 228 228 / 50%);
                        }

                        .details-container {
                            border-top: 2px solid #e6e6e6;
                            background-color: #f1f1f1;
                        }
                    }
                }

                .row.view-details {
                    border-left: 2px solid #c5c5c5 !important;

                    &:hover {
                        cursor: default;
                        background-color: #fbfbfb;
                    }
                }

                .row.in-progress {
                    .filename,
                    .value,
                    .created,
                    fa-icon {
                        color: #a2a2a2;
                    }
                }

            }
        }

        .mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: white !important;
        }

        #feedback-form_btn {
            background-color: #f1f1f1 !important;

            &:hover {
                background-color: white !important;
            }
        }

        .scrollspy-wrapper {
            scrollbar-color: #f1f1f1 #dfdfdf;

            h3 {
                border-top: 1px solid #d0d0d0;
            }

            h3, h4 {
                color: #000;
            }
            
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #dfdfdf;
                border: 3px solid #f1f1f1;
            }
        }

        .scrollspy-menu {
            li.current-section {
                border-left: 3px solid #666;
            }
        }

    }

    //--DARK MODE COLOR THEME--//
    &.dark {
        h2 {
            border-bottom: 3px solid #424242;
        }

        .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
            border-color: #353535;
        }

        .mat-tab-header-pagination-chevron {
            border-color: #8e8e8e;
        }

        #nav-logo_container {
            h1 {
                color: white;
            }
        }
        #logo_dark {
            display: block;
        }

        #logo_light {
            display: none;
        }

        #points-wrapper {
            #points-container {
                background-color: #252525;
            }
        }

        #points-info_modal .arrow-up {
            border-bottom: 10px solid #252525;
        }

        .backTop {
            color: #b1b1b1;
        }

        .backTop_section {
            color: #5a5a5a;
        }
        
        #app-menu {
            #menu_logo {
                #menu_logo-link {
                    background-image: url(/assets/logo_quiq-text_sm-dark.png) !important;
                }
            }
        }

        mat-sidenav-content {
            background-color: #151515;
            color: #dadada;
        }

        mat-sidenav {
            .mat-drawer-inner-container {
                background-color: #212121;

                #app-menu {
                    #welcome-user {
                        .welcome-txt {
                            color: #e0e0e0;
                        }

                        #current-plan {
                            color: #c1c1c1;
                        }
                    }

                    #points-wrapper {
                        border-top: 1px solid #232323;

                        #points-container {
                            background-color: #191919;
                            color: #c1c1c1;
                        }

                        #points-divider {
                            color: #7d7d7d;
                        }

                        #points-left {
                            color: #15c235;
                        }

                        #points-icon {
                            background: url("../assets/icon_points-light.png") no-repeat;
                        }
                    }

                    li {
                        color: #6B6B6B;
                        padding: 15px 0;
                        margin: 1px 0;

                        &.route-active {
                            color: #E8E8E8;
                            background-color: #2f2f2f;
                            border-right: 4px solid #4a4a4a;

                            h4 {
                                font-weight: 500;
                            }

                            &:hover {
                                color: #E8E8E8;
                            }
                        }

                        &:hover {
                            color: #959595;
                        }
                    }
                }
            }
        }

        #about-us_wrapper {
            .circle-bg {
                background: #2b2a2a; 
                border: 5px solid #333333;
            }

            .about-section {
                h3 {
                    color: #c5c5c5;
                }

                p, li {
                    color: #9c9c9c;
                }
            }
        }

        #home-wrapper {
            h1 {
                color: #fff;
            }

            &:hover {
                .highlight-text {
                    color: black !important;
                }
            }

            .highlight-text {
                color: black;
                background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 0%) !important;
            }
        }

        h2,
        h3,
        h5 {
            color: #fff;
        }

        .mat-tab-label,
        .mat-tab-link {
            color: #dadada;
        }

        .mat-tab-label-active {
            color: #fff;
        }

        .faq-container {
            mat-expansion-panel {
                background-color: #252525;
                border-color: #333;

                &:not(.mat-expanded):not([aria-disabled=true]) {
                    .mat-expansion-panel-header:hover {
                        background: transparent;
                    }
                }

                .mat-expansion-panel-body,
                mat-panel-title {
                    color: #dadada;
                }
            }
        }

        .credit-available-wrapper {
            .block {
                border-color: #313131;

                .title {
                    border-color: #333;
                }

                .cash-count {
                    background-color: #252525;
                }
            }
        }

        #upload-histories-wrapper {
            #multiple-downloads_row {
                button {
                    border: 1px solid #545454;
                    background-color: #4a4a4a;
                    color: #dadada;

                    span {
                        color: #bdbdbd !important;
                    }


                    &:hover {
                        background-color: #656565 !important;
                        border: 1px solid #545454;
                    }
                }
            }
        }

        #uploader-wrapper {
            .upload {
                border-color: #5A5A5A;
                background-color: #303030;

                mat-icon {
                    color: #ABABAB;
                }
            }

            h4 {
                color: #ABABAB;
            }

            .faux-button {
                background-color: #4A4A4A;
                border-color: #4A4A4A;

                &:hover {
                    background-color: #5a5a5a;
                }
            }

            #choose-format_wrapper {
                .mat-checkbox {
                    .mat-checkbox-layout {
                        border: 0;
                        background-color: #272727;
                    }
                }

                .mat-checkbox-label {
                    color: #dadada;
                }
            }

            .upload {
                #upload-ready_wrapper {
                    .upload-cost_header {
                        color: white;
                    }

                    h5 {
                        &:first-child {
                            color: #fff;
                        }
                    }

                    h6 {
                        color: #dadada;
                    }
                }
            }
        }

        #upload-item {
            .head {
                .col {
                    span {
                        color: #fff;
                    }
                }
            }

            .body {
                .row {
                    background-color: #2b2b2b;

                    &:hover {
                        background-color: #383838;
                        cursor: pointer;
                    }

                    .filename,
                    .size,
                    .created,
                    .upload-size {
                        color: #dadada;
                    }

                    .row-container {
                        .loading-details {
                            background: rgb(0 0 0 / 50%);
                        }

                        .details-container {
                            border-top: 2px solid #2c2c2c;
                            background-color: #202020;
                        }
                    }

                }

                .row.view-details {
                    border-left: 2px solid #4d4d4d !important;

                    &:hover {
                        cursor: default;
                        background-color: #2b2b2b;
                    }
                }

                .row.in-progress {
                    .filename,
                    .value,
                    .created,
                    fa-icon {
                        color: #a2a2a2;
                    }
                }

                .row.download-row {
                    &:hover {
                        .filename{
                            color: #fff;
                        }

                    }
                }
            }
        }

        footer {
            .container {
                color: #dadada;
            }
        }

        button.confirm {
            background-color: #545454 !important;
        }

        button.cancel {
            background-color: #3c3c3c !important;
        }

        .mat-paginator-container,
        .mat-select-value,
        .mat-select-arrow {
            color: #bdbdbd;
        }

        .mat-button-disabled {
            color: #4a4a4a !important;
        }

        #upload-progress_wrapper {
            .upload-progress-bar {
                padding: 10px 20px;
                background-color: #575757;
                border: 1px solid #666;

                #progress-bar {
                    .stop-upload-btn,
                    .complete-icon {
                        background-color: #575757;
                    }
                }
            }
        }

        .mat-form-field-appearance-fill .mat-form-field-flex {
           background-color: #333 !important;
        }

        .mat-form-field-label,
        .mat-hint {
            color: #ccc;
        }

        #feedback-form_btn {
            background-color: #333 !important;

            &:hover {
                background-color: #000 !important;
            }

            mat-icon {
                color: white;
            }
        }

        .mat-checkbox.mat-accent .mat-checkbox-background{
            background-color: #999;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-background,
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
            background-color: #666;
        }

        .scrollspy-wrapper {
            scrollbar-color: #2f2f2f #212121;

            h3 {
                border-top: 1px solid #222;
            }

            h3, h4 {
                color: white;
            }
            
            &::-webkit-scrollbar-track {
                background: #2f2f2f;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #212121;
                border: 3px solid #2f2f2f;
            }

            .section {
                p {
                    color: #d0d0d0;
                }

                ul {
                    li {
                        color: #d0d0d0;
                    }
                }
            }
        }

        .scrollspy-menu {
            li.current-section {
                border-left: 3px solid white;
            }
        }
    }

}

body.light {
    .mat-dialog-container {
        background-color: #f5f5f5;
        border: 1px solid #999;
    }

    .mat-raised-button,
    .mat-fab,
    .mat-mini-fab {
        background-color: #909090 !important;
    }
    .mat-flat-button {
        background-color: #909090 !important;
        display: flex;
        align-items: center;
        .mat-button-wrapper {
            margin-top: 2px;
        }
        &.cancel {
            background-color: #bfbfbf !important;
        }
    }
}

body.dark {
    .feedback-form_wrapper {
        .mat-dialog-container {
            background-color: #fff !important;
            color: #666 !important;
        }
    }

    .mat-dialog-container {
        background-color: #383838 !important;
        border: 1px solid #383838 !important;
        color: #ffffff !important;
    }

    .mat-flat-button,
    .mat-raised-button,
    .mat-fab,
    .mat-mini-fab {
        background-color: #777777 !important;
    }
}

mat-paginator {
    background-color: transparent !important;
}

mat-sidenav.mat-drawer {
    background-color: #f0f0f0;
    width: 150px;
    border: 0;

    .mat-drawer-inner-container {
        overflow: hidden;
    }
}

@include tablet-portrait_max {
    mat-sidenav.mat-drawer {
        width: 130px;
    }
}

@include tablet_min {
    .container {
        width: 90%;
    }
}

@include mobile_max {
    .container {
        width: 92%;
        padding: 30px 0;

        .mat-tab-group {
            margin-left: 0;
        }

        .mat-tab-label {
            padding: 0 15px;
        }
    }
}

//-----HEADERS-----//

h1 {
    font-size: 22px;
    line-height: 29px;
    font-weight: 500;
}
 
h2 {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 21px;
    line-height: 36px;
    font-weight: 500;
    border-bottom: 3px solid #cfcfcf;
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #616161;
}

h2.page-header {
    font-size: 30px;
    font-family: "Sulphur Point", Arial, Helvetica, sans-serif;
    font-weight: 500;
    text-transform: none;
    color: #333;

    span {
        color: #15c235;
        margin: 0 0 0 -10px;
        font-weight: 600;
    }

    @include tablet-portrait_max {
        font-size: 23px;
    }
}

h3 {
    font-size: 20px;
    color: #666666;
    font-weight: 300;
}

h4 {
    font-size: 18px;
    line-height: 23px;
    font-weight: 300;
}

h5 {
    font-size: 16px;
}

@include range_tablet-desktop {
    h1 {
        font-size: 20px;
        line-height: 27px;
    }

    h2 {
        font-size: 24px;
        line-height: 33px;
    }

    h3 {
        font-size: 18px;
    }
}

@include tablet-portrait_max {
    h1 {
        font-size: 18px;
        line-height: 25px;
    }

    h3 {
        font-size: 16px;
    }

    h4 {
        font-size: 15px;
        line-height: 19px;
    }

    h5 {
        font-size: 14.5px;
    }
}

@include range_mobile-tablet {
    h2 {
        font-size: 20px;
        line-height: 28px;
    }
}

@include mobile-sm_max {
    h2 {
        font-size: 20px;
        line-height: 25px;
    }
}

//-----GLOBAL STYLING-----//

.active {
    transform: translateX(-100px);
}

p {
    font-size: 16px;
    line-height: 20px;
}

span.mat-checkbox-ripple {
    display: none !important;
}

span.mat-radio-ripple {
    display: none !important;
}

.logo-img {
    margin: 0 auto;
    display: block;
}

#feedback-form_btn {
	right: 30px;
    bottom: 70px;
    position: fixed;

	mat-icon {
		font-size: 29px;
		height: 29px;
		width: 29px;
		margin: -1px 0 0;
		color: #797979;
	}

	&:hover {
		mat-icon {
			color: #656565;
		}
	}
}

@include mobile_max {
    p {
        font-size: 13.5px;
        line-height: 17px;
    }
}

@include range_mobile-tablet {
    p {
        font-size: 14px;
        line-height: 19px;
    }
}

@include range_tablet-desktop {
    p {
        font-size: 15px;
        line-height: 21px;
    }
}

.backTop {
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    margin: 20px auto 45px;
    display: block;
}

.backTop_section {
    display: none;
    text-decoration: none;
    font-size: 13px;

    @include tablet-xl_max {
        display: block;
    }
}

//-----OVERRIDES-----//
button:focus {
    background-color: transparent;
}

button.confirm {
    background-color: #757575 !important;
    margin: 0 5px 0 0;
}

.mat-dialog-container {
    border-radius: 0 !important;

    .dialog-buttons_container {
        margin: 15px 0 10px;
        text-align: center;
    }
}

.mat-badge-medium .mat-badge-content {
    width: 19px;
    height: 19px;
    line-height: 20px;
    font-size: 10.5px;
}

.pointer {
    cursor: pointer;
}

//----GLOBAL ELEMENTS----//

.changelog-wrapper {
    p {
        font-weight: bold;

        &:first-child {
            text-transform: uppercase;
            text-align: center;
            text-decoration: underline;
            font-size: 20px;
        }
    }

    ul {
        border-bottom: 1px dashed #ccc;
        padding-bottom: 15px;
        margin-bottom: 10px;
        padding-left: 20px;

        li {
            list-style-type: square;
            margin: 0 0 0 3px;
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}

#policies-row {
    margin: 5px 0 40px;
    color: #9e9e9e;
    font-weight: 200;

    a {
        margin: 0 10px 0 5px;
        color: #828282;
        font-size: 12px;
        font-weight: 400;
    }

    .active {
        text-decoration: none;
    }
}

.scrollspy-menu_wrapper {
    @include tablet-xl_max {
        display: none;
    }

    @include range_desktopSM-desktopMD {
        width: 20% !important;
    }
}

.scrollspy-wrapper {
    height: 75vh;
    overflow-y: scroll;

    h3, h4 {
        margin: 0 0 10px 0;
    }

    h3 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        padding-top: 30px;
    }

    h4 {
        font-size: 14px;
        text-decoration: underline;
    }

    @include tablet-xl_max {
        height: 100%;
        overflow-y: auto;
        width: 100% !important;
    }

    @include range_desktopSM-desktopMD {
        width: 80% !important;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
      
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
    }

    .section {
        padding: 10px 0;

        p {
            font-size: 13.5px;
        }

        &:last-child {
            padding-bottom: 40px;
        }

        ol {
            margin: 0;
            padding: 0;
        }

        ul, ol {
            li {
                margin: 5px 0 5px 30px;
                font-size: 13.5px;
                list-style-type: square;
            }
        }

        .sub-section {
            padding-left: 30px;

            h5 {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.scrollspy-menu {
    li {
        padding: 0 0 0 20px;
        font-weight: 200;
        margin: 8px 0;
        font-size: 13px;
        line-height: 16px;

        &:hover {
            cursor: pointer;
            font-weight: 400;
        }
    }

    li.current-section {
        font-weight: 400;
        padding-left: 17px;
    }

    li.sub-section{
        display: none;
    }

    li.sub-section.parent-active {
        display: block;
        padding-left: 30px;
    }

    li.sub-section.parent-active.current-section {
        padding-left: 26px;
    }
}



@keyframes flip{
    0%{
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50%{
        transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    }
    100%{
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}


